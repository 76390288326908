import runtimeEnv from '@mars/heroku-js-runtime-env';

// Load the env object.
const env = runtimeEnv();

// this file reads the environmental variables into the app
export interface IConfig {
  REACT_APP_SAFIRI_API_URL: string;
  REACT_APP_SAFIRI_BOOKING_API_URL: string;
  REACT_APP_GTFS_API_URL: string;
  REACT_APP_GTFS_BRT_URL: string;
  REACT_APP_GTFS_MARINE_API_URL: string;
  REACT_APP_GTFS_LORRY_API_URL: string;
  REACT_APP_GTFS_DALADALA_API_URL: string;
  REACT_APP_GTFS_TAXI_API_URL: string;
  REACT_APP_GTFS_TRAIN_API_URL: string;
  REACT_APP_GTFS_SGR_API_URL: string;
  REACT_APP_GTFS_CARGO_API_URL: string;
  REACT_APP_GTFS_AIR_API_URL: string;
  REACT_APP_GTFS_MGR_API_URL: string;

  REACT_APP_SENTRY_DNS: string;
  REACT_APP_SENTRY_TRACE_SAMPLE_RATE: number;

  REACT_APP_TOKEN_API_ID: string;

  REACT_APP_DEV_MODE: boolean;
  REACT_APP_PRODUCTION_MODE: boolean;

  REACT_APP_COMPANY_NAME: string;
  REACT_APP_APPLICATION_NAME: string;

  REACT_APP_SAFIRI_SUPPORT_EMAIL: string;
  REACT_APP_SAFIRI_SUPPORT_MOBILE: string;

  REACT_APP_VERSION: string;
  REACT_APP_NODE_ENV: 'development' | 'production';

  REACT_APP_SAFIRI_REPORTING_CHECK: string;

  REACT_APP_GOOGLE_MAP_API_URL: string;

  REACT_APP_GOOGLE_MAP_API_KEY: string;

  REACT_APP_S3_BUCKET_NAME: string;
  REACT_APP_S3_BUCKET_REGION: string;
  REACT_APP_S3_ACCESS_KEY: string;
  REACT_APP_S3_SECRET_KEY: string;
  REACT_APP_S3_URL: string;
  REACT_APP_S3_ENDPOINT?: string;
  REACT_APP_TRANSIT_WATCH_API: string;
  TRANSIT_WATCH_ABLY_TOKEN: string;
  REACT_APP_FLESPI_ENDPOINT: string;
  REACT_FLESPI_TOKEN: string;

  REACT_APP_WHATSAPP_API: string;
  REACT_APP_WHATSAPP_TOKEN: string;
}

const REACT_APP_NODE_ENV =
  env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV || 'development';

const config: IConfig = {
  REACT_APP_SAFIRI_API_URL:
    env.REACT_APP_SAFIRI_API_URL || process.env.REACT_APP_SAFIRI_API_URL || '',
  REACT_APP_SAFIRI_BOOKING_API_URL:
    env.REACT_APP_SAFIRI_BOOKING_API_URL ||
    process.env.REACT_APP_SAFIRI_BOOKING_API_URL ||
    '',
  REACT_APP_GTFS_API_URL:
    env.REACT_APP_GTFS_API_URL || process.env.REACT_APP_GTFS_API_URL || '',
  REACT_APP_GTFS_BRT_URL:
    env.REACT_APP_GTFS_BRT_URL || process.env.REACT_APP_GTFS_BRT_URL || '',
  REACT_APP_GTFS_MARINE_API_URL:
    env.REACT_APP_GTFS_MARINE_API_URL ||
    process.env.REACT_APP_GTFS_MARINE_API_URL ||
    '',
  REACT_APP_GTFS_LORRY_API_URL:
    env.REACT_APP_GTFS_LORRY_API_URL ||
    process.env.REACT_APP_GTFS_LORRY_API_URL ||
    '',
  REACT_APP_GTFS_DALADALA_API_URL:
    env.REACT_APP_GTFS_DALADALA_API_URL ||
    process.env.REACT_APP_GTFS_DALADALA_API_URL ||
    '',
  REACT_APP_GTFS_TAXI_API_URL:
    env.REACT_APP_GTFS_TAXI_API_URL ||
    process.env.REACT_APP_GTFS_TAXI_API_URL ||
    '',
  REACT_APP_GTFS_TRAIN_API_URL:
    env.REACT_APP_GTFS_TRAIN_API_URL ||
    process.env.REACT_APP_GTFS_TRAIN_API_URL ||
    '',
  REACT_APP_GTFS_SGR_API_URL:
    env.REACT_APP_GTFS_SGR_API_URL ||
    process.env.REACT_APP_GTFS_SGR_API_URL ||
    '',
  REACT_APP_GTFS_CARGO_API_URL:
    env.REACT_APP_GTFS_CARGO_API_URL ||
    process.env.REACT_APP_GTFS_CARGO_API_URL ||
    '',
  REACT_APP_GTFS_MGR_API_URL:
    env.REACT_APP_GTFS_MGR_API_URL ||
    process.env.REACT_APP_GTFS_MGR_API_URL ||
    '',
  REACT_APP_GTFS_AIR_API_URL:
    env.REACT_APP_GTFS_AIR_API_URL ||
    process.env.REACT_APP_GTFS_AIR_API_URL ||
    '',

  REACT_APP_SENTRY_DNS:
    env.REACT_APP_SENTRY_DNS || process.env.REACT_APP_SENTRY_DNS || '',
  REACT_APP_SENTRY_TRACE_SAMPLE_RATE:
    Number.parseFloat(
      env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE ||
        (process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE as string),
    ) || 1.0,

  REACT_APP_TOKEN_API_ID:
    env.REACT_APP_TOKEN_API_ID || process.env.REACT_APP_TOKEN_API_ID || '',

  REACT_APP_NODE_ENV,
  REACT_APP_DEV_MODE: REACT_APP_NODE_ENV?.toLowerCase() !== 'production',
  REACT_APP_PRODUCTION_MODE: REACT_APP_NODE_ENV?.toLowerCase() === 'production',

  REACT_APP_COMPANY_NAME:
    env.REACT_APP_COMPANY_NAME ||
    process.env.REACT_APP_COMPANY_NAME ||
    'Safiri',
  REACT_APP_APPLICATION_NAME:
    env.REACT_APP_APPLICATION_NAME ||
    process.env.REACT_APP_APPLICATION_NAME ||
    'Safiri Admin',

  REACT_APP_SAFIRI_SUPPORT_EMAIL:
    env.REACT_APP_SAFIRI_SUPPORT_EMAIL ||
    process.env.REACT_APP_SAFIRI_SUPPORT_EMAIL ||
    'support@safiri.app',
  REACT_APP_SAFIRI_SUPPORT_MOBILE:
    env.REACT_APP_SAFIRI_SUPPORT_MOBILE ||
    process.env.REACT_APP_SAFIRI_SUPPORT_MOBILE ||
    '+255 766 204 211',

  REACT_APP_VERSION:
    env.npm_package_version ||
    env.$npm_package_version ||
    env.REACT_APP_VERSION ||
    process.env.REACT_APP_VERSION,

  REACT_APP_SAFIRI_REPORTING_CHECK:
    process.env.REACT_APP_SAFIRI_REPORTING_CHECK || 'sales report',

  REACT_APP_GOOGLE_MAP_API_URL:
    env.REACT_APP_GOOGLE_MAP_API_URL ||
    process.env.REACT_APP_GOOGLE_MAP_API_URL,

  REACT_APP_GOOGLE_MAP_API_KEY:
    env.REACT_APP_GOOGLE_MAP_API_KEY ||
    process.env.REACT_APP_GOOGLE_MAP_API_KEY,

  REACT_APP_S3_BUCKET_NAME:
    env.REACT_APP_S3_BUCKET_NAME || process.env.REACT_APP_S3_BUCKET_NAME,
  REACT_APP_S3_BUCKET_REGION:
    env.REACT_APP_S3_BUCKET_REGION || process.env.REACT_APP_S3_BUCKET_REGION,
  REACT_APP_S3_ACCESS_KEY:
    env.REACT_APP_S3_ACCESS_KEY || process.env.REACT_APP_S3_ACCESS_KEY,
  REACT_APP_S3_SECRET_KEY:
    env.REACT_APP_S3_SECRET_KEY || process.env.REACT_APP_S3_SECRET_KEY,
  REACT_APP_S3_URL:
    env.REACT_APP_S3_URL ||
    process.env.REACT_APP_S3_URL ||
    'https://bucket.safiri.app',
  REACT_APP_S3_ENDPOINT:
    env.REACT_APP_S3_ENDPOINT || process.env.REACT_APP_S3_ENDPOINT,

  REACT_APP_TRANSIT_WATCH_API:
    env.REACT_APP_TRANSIT_WATCH_API ||
    process.env.REACT_APP_TRANSIT_WATCH_API ||
    '',
  TRANSIT_WATCH_ABLY_TOKEN:
    env.REACT_APP_TRANSIT_WATCH_ABLY_TOKEN ||
    process.env.REACT_APP_TRANSIT_WATCH_ABLY_TOKEN ||
    '',
  REACT_APP_FLESPI_ENDPOINT:
    env.REACT_APP_FLESPI_ENDPOINT ||
    process.env.REACT_APP_FLESPI_ENDPOINT ||
    'https://flespi.io/gw',
  REACT_FLESPI_TOKEN:
    env.REACT_FLESPI_TOKEN ||
    process.env.REACT_FLESPI_TOKEN ||
    'fHCaAcQLD5tzzdPkDpYhmHTbVPr13Nl9iN9HONghGzG3Cld3yZaJuTIfxZ6qBfnJ',
  REACT_APP_WHATSAPP_API:
    env.REACT_APP_WHATSAPP_API || 'https://api.ultramsg.com/instance71793',
  REACT_APP_WHATSAPP_TOKEN:
    env.REACT_APP_WHATSAPP_TOKEN || process.env.REACT_APP_WHATSAPP_TOKEN || '',
};

export { config };
