import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import * as action from './action-types';

// TypeScript Types
import {
  IActiveTrip,
  ICompany,
  ICompanyUpdates,
  IQueryOptions,
  IQueryTimeTable,
  IExpense,
  IRouteUpdate,
  IDashboardSummaries,
  IDashboardIncomeSummaries,
  IWhatsAppGroup,
  ITicket,
} from './types';
import { ISignUp } from '../user/types';
import {
  ICreditTransaction,
  IPaymentRequest,
  IPushNotification,
  IUser,
  IUserUpdates,
} from '../../types/user';
import { IOfficeFormInput, IOffice } from '../../types/office';
import { ICity } from '../../types/city';
import { IResponseError } from '../../types/errors';

import { config } from '../../config';
import requestRetry, { ResponseError } from '../../lib/request';

import { errorCodeMap } from '../../constants';
import { COMPANY_TYPE, GTFS_API } from '../../constants/company';
import * as routes from '../../constants/routes';

import { findUser, findUserByMobile, logoutUser } from '../user/actions';
import { setApiUrl } from '../gtfs/actions';
import { CURRENCY } from '../../constants/currencies';
import { IActivityLog } from '../../types/logs';
import { IPaymentOption } from '../../types/payment-options';
import {
  IConfigFile,
  ICreateWebsiteConfig,
  IUpdateWebsiteConfig,
} from '../../constants/config';
import { getAllBusinessStaff } from '../business/actions';
import isEmpty from 'lodash/isEmpty';

const {
  REACT_APP_SAFIRI_BOOKING_API_URL,
  REACT_APP_WHATSAPP_TOKEN,
  REACT_APP_WHATSAPP_API,
} = config;

export const setApiError = createAction(action.SET_API_ERROR);
export const clearApiError = createAction(action.CLEAR_API_ERROR);

export const startCompanyUpdate = createAction(action.START_COMPANY_UPDATE);
export const saveCompany = createAction(action.SAVE_COMPANY);
export const saveCompanies = createAction(action.SAVE_COMPANIES);

export const endCompanyUpdate = createAction(action.END_COMPANY_UPDATE);

export const resetState = createAction(action.RESET_STATE);
export const resetLoading = createAction(action.RESET_LOADING);

// staff actions
export const saveStaff = createAction(action.SAVE_STAFF);

export const saveTrips = createAction(action.SAVE_TRIPS);

export const saveTripsMade = createAction(action.SAVE_MADE_TRIPS);

// company sales actions

export const saveTicketSold = createAction(action.SAVE_TICKETS_SOLD);

export const savePeriodSales = createAction(action.SAVE_PERIODICAL_SALES);

export const savePeriodicalParcelDeliverySales = createAction(
  action.SAVE_PERIODICAL_PARCEL_DELIVERY_SALES,
);

export const saveSentParcels = createAction(action.SAVE_SENT_PARCELS);

export const saveDeliveredParcels = createAction(action.SAVE_DELIVERED_PARCELS);

// expense actions
export const saveExpenses = createAction(action.SAVE_EXPENSES);

export const saveLeasedVehicles = createAction(action.SAVE_LEASED_VEHICLES);

export const saveDashboardSummaries = createAction(
  action.SAVE_DASHBOARD_SUMMARIES,
);

export const saveDashboardIncomeSummaries = createAction(
  action.SAVE_DASHBOARD_INCOME_SUMMARIES,
);

export const saveCompanyWebsiteConfig = createAction(
  action.SAVE_WEBSITE_CONFIG,
);

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET so that it can select a company
 *
 * @param values (an object with optional keys - tinNumber, email, uuid, id)
 * @param requestFn - (Optional) fetcher function
 * @returns {ICompany | IResponseError}
 */
export const findCompany =
  (values: IQueryOptions, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<null | ICompany> => {
    try {
      // clear api error
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      const Keys: string[] = Object.keys(values);
      const firstQueryItem = values[Keys[0]];

      // find Company
      const result = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.FIND_COMPANY}${Keys[0]}=${firstQueryItem}`,
      );

      await dispatch(
        setApiUrl(
          GTFS_API[result.companyType || COMPANY_TYPE.UPCOUNTRY_CARGO],
        ) as any,
      );
      // save selected company
      await dispatch(saveCompany(result) as any);

      // End loading
      await dispatch(endCompanyUpdate());

      return result;
    } catch (e) {
      const error = e as ResponseError;
      await dispatch(setApiError(e));
      await dispatch(endCompanyUpdate());

      // TODO: Throw the error

      // logout and reset state if company doesn't exist
      if (error?.errorCode === errorCodeMap.COMPANY_DOESNT_EXIST)
        await dispatch(logoutUser() as any);

      // return the error from the API
      return null;
    }
  };

/**
 *
 * @param tinNumber
 * @param uuid
 * @param requestFn
 */
export const getAllStaff =
  (
    { tinNumber, uuid }: { tinNumber?: string; uuid?: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser[]> => {
    try {
      // dispatch an action to clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      const api = Object.entries({ tinNumber, uuid }).reduce(
        (acc, key, index) => {
          if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
          return `${acc}&${key[0]}=${key[1]}`;
        },
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_ALL_STAFF}`,
      );

      // find all staff
      const allStaff = await requestFn<IUser[]>(`${api}`);

      // save selected company
      await dispatch(saveStaff(allStaff) as any);

      // End loading
      await dispatch(endCompanyUpdate());

      return allStaff;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endCompanyUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET and fetch all companies
 *
 * @param requestFn - (Optional) fetcher function
 * @returns {ICompany | IResponseError} - Returns a company or response error
 */
export const getAllCompanies =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany[]> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Get all Companies
      const allCompanies = await requestFn<ICompany[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ALL_COMPANIES}`,
      );

      // Save all Companies
      await dispatch(saveCompanies(allCompanies) as any);

      // End loading
      dispatch(endCompanyUpdate());

      return allCompanies;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endCompanyUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET and fetch all companies
 *
 * @param requestFn - (Optional) fetcher function
 * @returns {ICompany | IResponseError} - Returns a company or response error
 */
export const findFirstRandomCompany =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ResponseError | ICompany> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Get all Companies
      const result = await requestFn<ICompany[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ALL_COMPANIES}`,
      );

      // grab the first company
      const company = result[0];

      dispatch(
        setApiUrl(
          GTFS_API[company.companyType || COMPANY_TYPE.UPCOUNTRY_CARGO],
        ) as any,
      ); // save company to redux
      await dispatch(saveCompany(company) as any);

      // End loading
      dispatch(endCompanyUpdate());

      return company;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endCompanyUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST so that it can register the user
 *
 * @param {ISignUp} values - User details to be registered
 * @param {Function} requestFn - Request function (Optional)
 *
 * @return {IResponseError | IUser}
 */
export const createStaff =
  (values: ISignUp, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser> => {
    // setup the values to update
    const userUpdates: IUserUpdates = {
      role: values.role,
      tinNumber: values.tinNumber || undefined,
      uuid: values.uuid || undefined,
      officeId: values?.office?.id || undefined,
    };

    try {
      // clear apiErrors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Register User
      let response = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.REGISTER_SAFIRI_USER}`,
        {
          method: 'POST',
          // Fetch API wants you to stringify this object
          body: JSON.stringify(values),
        },
      );

      // update user to become a staff member of this company
      response = await dispatch(updateStaff(response, userUpdates) as any);

      // Dispatch Redux Action for the reducer to save the token to the Redux Store
      dispatch(endCompanyUpdate());

      // return results of the request
      return response;
    } catch (e) {
      let response;

      if ((e as ResponseError).errorCode === errorCodeMap.MOBILE_ALREADY_EXISTS)
        response = await dispatch(
          findUserByMobile({
            countryCode: values.countryCode,
            phoneNumber: values.phoneNumber,
          }) as any,
        );

      if ((e as ResponseError).errorCode === errorCodeMap.EMAIL_ALREADY_EXISTS)
        response = await dispatch(findUser(values?.email as string) as any);

      if (!response.error) {
        response = await dispatch(updateStaff(response, userUpdates) as any);

        dispatch(endCompanyUpdate());

        return response;
      }

      dispatch(setApiError(e));
      dispatch(endCompanyUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET and fetch all cities
 *
 * @param requestFn - (Optional) fetcher function
 * @returns {ICity | IResponseError} - Returns a company or response error
 */
export const getAllCities =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICity> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Get all Cities
      const allCities = await requestFn<ICity>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_ALL_CITIES}`,
      );

      // End loading
      dispatch(endCompanyUpdate());

      return allCities;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST to add a new office
 *
 *
 * @param {IOfficeFormInput} office office details to be added to the company
 * @param param0 {companyId: string}
 * @param {Function} requestFn - Request function (Optional)
 * @return {IResponseError | ICompany}
 */
export const addCompanyOffice =
  (
    office: IOfficeFormInput,
    {
      companyId,
    }: {
      companyId: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany> => {
    try {
      // clear apiErrors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Add office
      const response = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ROUTE_UPDATE}${companyId}${routes.OFFICES}`,
        {
          method: 'POST',
          // Fetch API wants you to stringify this object
          body: JSON.stringify(office),
        },
      );

      // dispatch an action to save the company
      dispatch(saveCompany(response) as any);

      // Dispatch Redux Action for the reducer to save the token to the Redux Store
      dispatch(endCompanyUpdate());

      // return results of the request
      return response;
    } catch (error) {
      // set api Error if any
      dispatch(setApiError(error));

      // end update
      dispatch(endCompanyUpdate());

      // return the error from the API
      return error as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT and updates this office from the company offices[]
 *
 *
 * @param {IOffice} office office details to be removed from company
 * @param param0 {companyId: string}
 * @param {Function} requestFn - Request function (Optional)
 */
export const updateCompanyOffice =
  (
    office: IOffice,
    {
      companyId,
    }: {
      companyId: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany> => {
    try {
      // clear api error if any
      dispatch(clearApiError());

      // start company update
      dispatch(startCompanyUpdate());

      // send an HTTP request to update an office
      const response = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ROUTE_UPDATE}${companyId}${routes.OFFICES}/${office.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(office),
        },
      );

      // update the company offices
      dispatch(saveCompany(response) as any);

      // dispatch action to end company update
      dispatch(endCompanyUpdate());

      // return results of the request
      return response;
    } catch (e) {
      // set api Error if any
      dispatch(setApiError(e));

      // end update
      dispatch(endCompanyUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE and remove this office from the company
 *
 *
 * @param {IOffice} office office details to be removed from company
 * @param param0 {companyId: string}
 * @param {Function} requestFn - Request function (Optional)
 */
export const deleteCompanyOffice =
  (
    office: IOffice,
    {
      companyId,
    }: {
      companyId: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany> => {
    try {
      // clear apiErrors
      dispatch(clearApiError());

      // dispatch action to set loading
      dispatch(startCompanyUpdate());

      // Remove office on the backend
      const response = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ROUTE_UPDATE}${companyId}${routes.OFFICES}/${office.id}`,
        {
          method: 'DELETE',
        },
      );

      // update the company offices
      dispatch(saveCompany(response) as any);

      // dispatch action to end company update
      dispatch(endCompanyUpdate());

      // return results of the request
      return response;
    } catch (e) {
      // set api Error if any
      dispatch(setApiError(e));
      // end update    dispatch(endCompanyUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE and remove this staff from the company
 *
 *
 * @param staff Staff member to be removed from company
 * @param tinNumber TinNumber of the company
 * @param requestFn Request function
 */
export const removeStaff =
  (staff: IUser, tinNumber?: string, uuid?: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser> => {
    try {
      // clear apiErrors
      dispatch(clearApiError());

      // dispatch action to set loading
      dispatch(startCompanyUpdate());

      // updated removed staff
      // Update user
      const api = Object.entries({ tinNumber, uuid })
        .filter((query) => !isEmpty(query))
        .reduce((acc, key, index) => {
          if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
          return `${acc}&${key[0]}=${key[1]}`;
        }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.REMOVE_STAFF}${staff?._id}`);

      const response = await requestFn<IUser>(`${api}`, {
        method: 'DELETE',
        body: JSON.stringify(staff),
      });

      if (tinNumber)
        // reload all staff for this company
        await dispatch(getAllStaff({ tinNumber }) as any);

      if (uuid)
        // reload all staff for this business
        await dispatch(getAllBusinessStaff(uuid) as any);

      // dispatch action to end company update
      dispatch(endCompanyUpdate());

      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      // return the error from the API
      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT and updates this staff from the company
 *
 *
 * @param staff Details of staff to be update of type IUser
 * @param userUpdates Update data
 * @param requestFn Request function
 */
export const updateStaff =
  (
    staff: IUser,
    userUpdates: Partial<IUserUpdates>,
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser> => {
    try {
      // clear api error if any
      dispatch(clearApiError());

      // start company update
      dispatch(startCompanyUpdate());

      // send an HTTP request to update staff
      const response = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.EDIT_PROFILE}${staff?._id}`,
        {
          method: 'PUT',
          body: JSON.stringify(userUpdates),
        },
      );

      // reload all staff members for this company
      if (userUpdates.tinNumber)
        await dispatch(
          getAllStaff({ tinNumber: userUpdates.tinNumber }) as any,
        );
      // reload all staff members for this business
      if (userUpdates.uuid)
        await dispatch(getAllBusinessStaff(userUpdates.uuid) as any);

      // dispatch action to end company update
      dispatch(endCompanyUpdate());

      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      // return the error from the API
      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT and updates this company's profile
 *
 * @param companyUpdates Company properties to be updated
 * @param requestFn request function
 */
export const updateCompany =
  (companyUpdates: ICompanyUpdates, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Get all Companies
      const response = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.UPDATE_COMPANY}${companyUpdates._id}`,
        {
          method: 'PUT',
          body: JSON.stringify(companyUpdates),
        },
      );

      dispatch(
        setApiUrl(
          GTFS_API[response.companyType || COMPANY_TYPE.UPCOUNTRY_CARGO],
        ) as any,
      ); // dispatch an action to save the company
      dispatch(saveCompany(response));

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE and remove a route from the company's array of routes
 *
 * @param param0 {route: string, companyId: string}
 * @param requestFn request function
 */
export const deleteCompanyRoute =
  ({ route, companyId }: IRouteUpdate, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ICompany | ResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      //dispatch action to set loading: true
      dispatch(startCompanyUpdate());

      // delete route from company
      const response = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ROUTE_UPDATE}${companyId}/route`,
        {
          method: 'DELETE',
          body: JSON.stringify({ route }),
        },
      );

      dispatch(
        setApiUrl(
          GTFS_API[response.companyType || COMPANY_TYPE.UPCOUNTRY_CARGO],
        ) as any,
      ); // dispatch action to save the company
      await dispatch(saveCompany(response) as any);

      // dispatch action to set loading false
      dispatch(endCompanyUpdate());
      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE and remove a route from the company's array of routes
 *
 * @param {string} tinNumber
 * @param requestFn request function
 */
export const deleteCompany =
  (tinNumber: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ICompany | ResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      //dispatch action to set loading: true
      dispatch(startCompanyUpdate());

      // delete route from company
      const response = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ROUTE_UPDATE}${tinNumber}`,
        {
          method: 'DELETE',
        },
      );

      // dispatch action to set loading false
      dispatch(endCompanyUpdate());
      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT and add a route to the company's array of routes
 *
 * @param IRouteUpdate - {route: string, companyId: string}
 * @param requestFn request function
 */
export const addCompanyRoute =
  ({ route, companyId }: IRouteUpdate, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ICompany | ResponseError> => {
    try {
      // clear api errors
      await dispatch(clearApiError() as any);

      //dispatch action to set loading: true
      await dispatch(startCompanyUpdate() as any);

      // delete route from company
      const response = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ROUTE_UPDATE}${companyId}/route`,
        {
          method: 'PUT',
          body: JSON.stringify({ route }),
        },
      );

      // dispatch action to update company
      await dispatch(saveCompany(response) as any);

      // dispatch action to set loading false
      await dispatch(endCompanyUpdate() as any);

      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST and create trips for a company
 *
 * @param companyUpdates Company properties to be updated
 * @param requestFn request function
 */
export const createTimetable =
  (trips: IQueryTimeTable, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ICompany | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      const id = trips._id;
      delete trips._id;

      // Create timetable
      const updatedCompany = await requestFn<ICompany>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.CREATE_TIMETABLE}${id}/timetable`,
        {
          method: 'POST',
          body: JSON.stringify(trips),
        },
      );

      // dispatch an action to save the company
      await dispatch(saveCompany(updatedCompany) as any);

      // End loading
      dispatch(endCompanyUpdate());

      return updatedCompany;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to fetch trip
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - Trips or API error
 */
export const fetchTrips =
  (query: any, requestFn = requestRetry): any | IResponseError =>
  async (dispatch: Dispatch): Promise<IActiveTrip | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      const queryString = Object.keys(query)
        .map((q, index) => {
          if (index === 0) return `?${q}=${query[q]}`;
          return `&${q}=${query[q]}`;
        })
        .join('');

      // Fetch Company trips
      const trips = await requestFn<IActiveTrip>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.TRIPS}${queryString}`,
      );

      // dispatch an action to save the company
      dispatch(saveTrips(trips));

      // End loading
      dispatch(endCompanyUpdate());

      return trips;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get trips made
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - Trips or API error
 */
export const getAllTripsMade =
  (query: { tinNumber: string }, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Fetch Company trips
      const trips = await requestFn<any>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.TRIPSMADE}?tinNumber=${query.tinNumber}`,
      );

      // dispatch an action to save the company
      dispatch(saveTripsMade(trips));

      // End loading
      dispatch(endCompanyUpdate());

      return trips;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get ticket sold
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - ticket sold or API error
 */
export const getAllTicketSold =
  (
    query: { tinNumber: string; dateBought: string; cancelled?: boolean },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Fetch company ticket sold
      let ticketSold = await requestFn<ITicket[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.TICKETS_SOLD}/active/${query.tinNumber}/${query.dateBought}`,
      );

      // if cancelled is true filter out cancelled tickets
      if (query.cancelled)
        ticketSold = ticketSold.filter((ticket) => ticket.cancelled === false);

      // dispatch an action to save the company
      dispatch(saveTicketSold(ticketSold));

      // End loading
      dispatch(endCompanyUpdate());

      return ticketSold;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get periodical ticket sales
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - periodical ticket sales or API error
 */
export const periodicalTicketSales =
  (
    query: { tinNumber: string; startDate?: string; endDate?: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.TICKETS_SOLD}`);

      // Fetch company ticket sold
      const periodicalSales = await requestFn<any>(`${api}`);

      // dispatch an action to save the company
      dispatch(savePeriodSales(periodicalSales));

      // End loading
      dispatch(endCompanyUpdate());

      return periodicalSales;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get periodical parcel delivery sales
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - periodical parcel delivery sales or API error
 */
export const periodicalParcelSales =
  (
    query: {
      tinNumber: string;
      startDate?: string;
      endDate?: string;
      delivered?: boolean;
      dateSent?: string;
      cancelled?: boolean;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_PARCELS}`);

      // Fetch company parcel delivery
      const parcels = await requestFn<any>(`${api}`);

      // dispatch an action to save the company
      if (query.startDate && query.endDate)
        dispatch(savePeriodicalParcelDeliverySales(parcels));

      // dispatch an action to save the company
      if (!query.startDate && !query.endDate)
        dispatch(saveSentParcels(parcels));

      if (query.delivered) dispatch(saveDeliveredParcels(parcels));

      // End loading
      dispatch(endCompanyUpdate());

      return parcels;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get periodical parcel delivery sales
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - periodical parcel delivery sales or API error
 */
export const fetchLeasedVehicles =
  (
    query: {
      tinNumber: string;
      startDate: string;
      endDate: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_LEASED_VEHICLES}`);

      // Fetch company parcel delivery
      const leasedVehicles = await requestFn<any>(`${api}`);

      // dispatch an action to save the company
      if (query.startDate && query.endDate)
        dispatch(saveLeasedVehicles(leasedVehicles));

      // End loading
      dispatch(endCompanyUpdate());

      return leasedVehicles;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST request to "activate" a trip
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - Active Trips or API error
 */
export const activateTrip =
  (postBody: any, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Activate Company trip
      await requestFn<any>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ACTIVATE_TRIP}`,
        {
          method: 'POST',
          body: JSON.stringify(postBody),
        },
      );

      // Fetch all trips for that day to update local storage
      const trips = await dispatch(
        fetchTrips({
          tinNumber: postBody?.tinNumber as string,
          seatsReserved: true,
          departureDate: postBody.departureDate,
        }) as any,
      );

      // End loading
      dispatch(endCompanyUpdate());

      return trips;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT request to "reactivate" a cancelled trip
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - Active Trips or API error
 */
export const updateTrip =
  (
    postBody: { tinNumber: string; frequencyId: string; departureDate: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Reactivate Company trip
      await requestFn<any>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.UPDATE_TRIP}`,
        {
          method: 'PUT',
          body: JSON.stringify(postBody),
        },
      );

      // Fetch all trips for that day
      const trips = await dispatch(
        fetchTrips({
          tinNumber: postBody?.tinNumber,
          seatsReserved: true,
          departureDate: postBody.departureDate,
        }) as any,
      );

      // End loading
      dispatch(endCompanyUpdate());

      return trips;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST request to create a new expense
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | void} response - API error or void
 */

export const createExpense =
  (reqBody: IExpense[], requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IExpense[]> => {
    try {
      dispatch(clearApiError());

      dispatch(startCompanyUpdate());

      const res = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.CREATE_EXPENSE}`,
        {
          method: 'POST',
          body: JSON.stringify({ postBody: reqBody }),
        },
      );

      dispatch(endCompanyUpdate());

      return res.expenses;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT request to update
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | void} response - API error or void
 */
export const updateExpense =
  (postBody: IExpense, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IExpense> => {
    try {
      dispatch(clearApiError());

      dispatch(startCompanyUpdate());

      const res = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.UPDATE_EXPENSE}/${postBody.uuid}`,
        {
          method: 'PUT',
          body: JSON.stringify(postBody),
        },
      );

      dispatch(endCompanyUpdate());

      return res.expense;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to fetch all trip expenses
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | IExpense[]} response - API error or array of expenses
 */
export const getExpenses =
  (
    {
      _id,
      sDate,
      eDate,
    }: { _id: string | undefined; sDate?: string; eDate?: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IExpense[]> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());

      const expenses = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_COMPANY_EXPENSES}/${_id}?endDate=${eDate}&startDate=${sDate}`,
        { method: 'GET' },
      );

      dispatch(saveExpenses(expenses.expenses));

      dispatch(endCompanyUpdate());

      return expenses.expenses;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE request to delete a given expense
 *
 * @param reqBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | void} response - API error or array of expenses
 */

export const deleteExpense =
  (reqBody: { uuid: string | undefined }, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | void> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());
      console.log(reqBody.uuid);
      await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.DELETE_EXPENSE}/${reqBody.uuid}`,
        {
          method: 'DELETE',
        },
      );

      dispatch(endCompanyUpdate());
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

export interface IGeneratePDFResponse {
  url: string;
  data: {
    ETag: string;
    VersionId: string;
    Location: string;
    key: string;
    Key: string;
    Bucket: string;
  };
}

export interface IGenerateXLSXResponse {
  url: string;
  data: {
    ETag: string;
    VersionId: string;
    Location: string;
    key: string;
    Key: string;
    Bucket: string;
  };
}

export const downloadTicketSalesPdf =
  (
    query: {
      tinNumber: string;
      dateBought?: string;
      departureDate?: string;
      frequencyId?: string;
      startDate?: string;
      endDate?: string;
      boughtBy?: string;
      fromStopId?: string;
      toStopId?: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IGeneratePDFResponse | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GENERATE_TICKET_SALES_PDF}`);

      // parcel sales pdf
      const response = await requestFn<IGeneratePDFResponse>(api);

      if (response.url) window?.open(response.url, '_blank')?.focus();

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

export const downloadTicketSalesXlsx =
  (
    query: {
      tinNumber: string;
      dateBought?: string;
      departureDate?: string;
      frequencyId?: string;
      startDate?: string;
      endDate?: string;
      boughtBy?: string;
      fromStopId?: string;
      toStopId?: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IGeneratePDFResponse | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GENERATE_TICKET_SALES_XLSX}`);

      // parcel sales pdf
      const response = await requestFn<IGeneratePDFResponse>(api);

      if (response.url) window?.open(response.url, '_blank')?.focus();

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

export const downloadExpensePdf =
  (
    requestBody: {
      companyId: string;
      startDate: string;
      endDate: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IGeneratePDFResponse | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(requestBody).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GENERATE_EXPENSES_PDF}`);

      // expense pdf
      const response = await requestFn<IGeneratePDFResponse>(api);

      if (response.url) window?.open(response.url, '_blank')?.focus();

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

export const downloadParcelSalesPdf =
  (
    query: {
      tinNumber: string;
      date: string;
      frequencyId?: string;
      endDate?: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IGeneratePDFResponse | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GENERATE_PARCEL_SALES_PDF}`);

      // parcel sales pdf
      const response = await requestFn<IGeneratePDFResponse>(api);

      if (response.url) window?.open(response.url, '_blank')?.focus();

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

export const downloadParcelSalesXlsx =
  (
    query: {
      tinNumber: string;
      date: string | undefined;
      endDate: string | undefined;
      origin?: string;
      createdBy?: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IGenerateXLSXResponse | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GENERATE_PARCEL_SALES_XLSX}`);

      // parcel sales pdf
      const response = await requestFn<IGenerateXLSXResponse>(api);

      if (response.url) window?.open(response.url, '_blank')?.focus();

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

export const downloadTripManifestPdf =
  (
    query: {
      tinNumber: string;
      frequencyId: string;
      departureDate: string;
      seatsReserved?: boolean;
      tripId: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IGeneratePDFResponse | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GENERATE_TRIP_MANIFEST_PDF}`);

      // pdf
      const response = await requestFn<IGeneratePDFResponse>(api);

      if (response.url) window?.open(response.url, '_blank')?.focus();

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST so that it can register the user
 *
 * @param {ISignUp} values - User details to be registered
 * @param {Function} requestFn - Request function (Optional)
 *
 * @return {IResponseError | IUser}
 */
export const sendPushNotification =
  (values: IPushNotification, companyId?: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser> => {
    try {
      // clear apiErrors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Send push notification
      let response = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.PUSH_NOTIFICATION_COMPANY}${companyId}${routes.SEND_PUSH_NOTIFICATION}`,
        {
          method: 'POST',
          // Fetch API wants you to stringify this object
          body: JSON.stringify(values),
        },
      );

      // Dispatch Redux Action for the reducer to save the token to the Redux Store
      dispatch(endCompanyUpdate());

      // return results of the request
      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endCompanyUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST request to manually upadate user's credit
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IUser | IResponseError} response - user or API error
 */
export const addUserCredit =
  (
    query: {
      userId?: string;
      amount: string;
      tinNumber?: string;
      currency: CURRENCY;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IUser | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // api
      const api = `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ADD_USER_CREDIT}`;

      const response = await requestFn<IUser>(api, {
        method: 'POST',
        body: JSON.stringify(query),
      });

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST request to manually upadate user's wallet debit amount
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IUser | IResponseError} response - user or API error
 */
export const addUserWalletDebit =
  (
    query: {
      userId?: string;
      amount: string;
      tinNumber?: string;
      currency: CURRENCY;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IUser | IResponseError> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // api
      const api = `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ADD_USER_WALLET_DEBIT}`;

      const response = await requestFn<IUser>(api, {
        method: 'POST',
        body: JSON.stringify(query),
      });

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

export const getPaymentRequests =
  (query: { id: string }, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IPaymentRequest> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // api
      const api = `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_USER_PAYMENT_REQUESTS}/?id=${query.id}`;
      const response = await requestFn<IPaymentRequest>(api, {
        method: 'GET',
      });
      // End loading
      dispatch(endCompanyUpdate());

      return response as IPaymentRequest;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

export const getCreditTransactions =
  (
    query: { page: string; take?: string; uuid: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICreditTransaction> => {
    try {
      // api
      const api = `${REACT_APP_SAFIRI_BOOKING_API_URL}${
        routes.GET_USER_CREDIT_TRANSACTION
      }?page=${query.page}&take=${query?.take || 50}&uuid=${query.uuid}`;
      const response = await requestFn<ICreditTransaction>(api, {
        method: 'GET',
      });

      return response as ICreditTransaction;
    } catch (error) {
      // Return the error from the API
      return error as IResponseError;
    }
  };

export const getUserTransactions =
  (
    query: { page: string; take?: string; uuid: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICreditTransaction> => {
    try {
      // api
      const api = `${REACT_APP_SAFIRI_BOOKING_API_URL}${
        routes.GET_TRANSACTIONS
      }?page=${query.page}&take=${query?.take || 50}&uuid=${query.uuid}`;
      const response = await requestFn<ICreditTransaction>(api, {
        method: 'GET',
      });

      return response as ICreditTransaction;
    } catch (error) {
      // Return the error from the API
      return error as IResponseError;
    }
  };

export const getCompanyPaymentRequests =
  (query: { tinNumber: string }, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IPaymentRequest> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // api

      const api = `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_USER_PAYMENT_REQUESTS}?tinNumber=${query.tinNumber}`;
      const response = await requestFn<IPaymentRequest>(api, {
        method: 'GET',
      });
      // End loading
      dispatch(endCompanyUpdate());

      return response as IPaymentRequest;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

export const verifyUserTransaction =
  (query: { token: string }, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | any> => {
    try {
      // Clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      const api = `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.VERIFY_USER_TRANSACTION}`;

      await requestFn(api, {
        method: 'POST',
        body: JSON.stringify(query),
      });

      // End loading
      dispatch(endCompanyUpdate());

      return;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET and fetch all cities
 *
 * @param requestFn - (Optional) fetcher function
 * @returns {IActivityLog | IResponseError} - Returns activity logs or response error
 */
export const getAllActivityLogs =
  (
    query: {
      entityRef?: string;
      entityType?: string;
      tinNumber?: string;
      companyId?: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IActivityLog[]> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Create dynamic api
      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_ALL_ACTIVITY_LOGS}`);

      // Get all activity logs that match
      const allLogs = await requestFn<IActivityLog[]>(api, { method: 'GET' });

      // End loading
      dispatch(endCompanyUpdate());

      return allLogs;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST request to create a new payment method
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | ICompany} response - API error or company
 */
export const createPaymentMethod =
  (param: IPaymentOption, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());

      const company = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.CREATE_PAYMENT_OPTION}`,
        { method: 'POST', body: JSON.stringify(param) },
      );

      dispatch(saveCompany(company));

      dispatch(endCompanyUpdate());

      return company as ICompany;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT request to update a payment method
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | ICompany} response - API error or company
 */
export const updatePaymentMethod =
  (
    param: {
      accountNumber: string;
      companyId: string;
      verificationCode?: string;
      default?: boolean;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());

      const company = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.UPDATE_PAYMENT_OPTION}/${param.accountNumber}?companyId=${param.companyId}`,
        { method: 'PUT', body: JSON.stringify(param) },
      );

      dispatch(saveCompany(company));

      dispatch(endCompanyUpdate());

      return company as ICompany;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE request to delete a payment method
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | ICompany} response - API error or company
 */
export const deletePaymentMethod =
  (
    param: { accountNumber: string; companyId: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | ICompany> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());

      const company = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.DELETE_PAYMENT_OPTION}/${param.companyId}?accountNumber=${param.accountNumber}`,
        { method: 'DELETE' },
      );

      dispatch(saveCompany(company));

      dispatch(endCompanyUpdate());

      return company as ICompany;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST request to create a new payment method for a user
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | IUser} response - API error or array of expenses
 */
export const createUserPaymentOption =
  (param: IPaymentOption, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());

      const user = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.CREATE_USER_PAYMENT_OPTION}`,
        { method: 'POST', body: JSON.stringify(param) },
      );

      dispatch(endCompanyUpdate());

      return user as IUser;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT request to update a user payment method
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | ICompany} response - API error or user
 */
export const updateUserPaymentOption =
  (
    param: {
      accountNumber: string;
      userId: string;
      verificationCode?: string;
      default?: boolean;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());

      const user = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.UPDATE_USER_PAYMENT_OPTION}/${param.accountNumber}?userId=${param.userId}`,
        { method: 'PUT', body: JSON.stringify(param) },
      );

      dispatch(endCompanyUpdate());

      return user as IUser;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE request to delete a user's payment method
 *
 * @param postBody - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IResponseError | IUser} response - API error or user
 */
export const deleteUserPaymentOption =
  (
    param: { accountNumber: string; userId: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser> => {
    try {
      dispatch(clearApiError());
      dispatch(startCompanyUpdate());

      const user = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.DELETE_USER_PAYMENT_OPTION}/${param.userId}?accountNumber=${param.accountNumber}`,
        { method: 'DELETE' },
      );

      dispatch(endCompanyUpdate());

      return user as IUser;
    } catch (error) {
      dispatch(setApiError(error as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return error as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get dashboard summaries
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IDashboardSummaries | IResponseError} response - dashboard summaries or API error
 */
export const getDashboardSummaries =
  (
    query: {
      tinNumber?: string;
      businessUuid?: string;
      startDate?: string;
      endDate?: string;
      yearly?: boolean;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IDashboardSummaries | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      const api = Object.entries(query).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_DASHBOARD_SUMMARIES}`);

      // Fetch company ticket sold
      const summaries = await requestFn<IDashboardSummaries>(api);

      // dispatch an action to save the company
      dispatch(saveDashboardSummaries(summaries));

      // End loading
      dispatch(endCompanyUpdate());

      return summaries;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get dashboard income summaries
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IDashboardIncomeSummaries | IResponseError} response - ticket sold or API error
 */
export const getDashboardIncomeSummaries =
  (
    query: {
      tinNumber: string;
      startDate: string;
      endDate: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IDashboardIncomeSummaries | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Fetch company ticket sold
      const summaries = await requestFn<IDashboardIncomeSummaries>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_DASHBOARD_INCOME_SUMMARIES}?tinNumber=${query.tinNumber}&startDate=${query.startDate}&endDate=${query.endDate}`,
      );

      // dispatch an action to save the company
      dispatch(saveDashboardIncomeSummaries(summaries));

      // End loading
      dispatch(endCompanyUpdate());

      return summaries;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get an agents dashboard income summaries
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IDashboardIncomeSummaries | IResponseError} response - ticket sold or API error
 */
export const getAgentDashboardIncomeSummaries =
  (
    query: {
      businessUuid: string;
      startDate: string;
      endDate: string;
    },
    requestFn = requestRetry,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<IDashboardIncomeSummaries | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Fetch company ticket sold
      const summaries = await requestFn<IDashboardIncomeSummaries>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_AGENT_DASHBOARD_INCOME_SUMMARIES}?businessUuid=${query.businessUuid}&startDate=${query.startDate}&endDate=${query.endDate}`,
      );

      // dispatch an action to save the company
      dispatch(saveDashboardIncomeSummaries(summaries));

      // End loading
      dispatch(endCompanyUpdate());

      return summaries;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request to get dashboard income summaries
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {IWhatsAppGroup | IResponseError} response - ticket sold or API error
 */
export const fetchAllWhatsAppGroups =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IWhatsAppGroup[] | IResponseError> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Fetch company ticket sold
      const whatsAppGroups = await requestFn<IWhatsAppGroup[]>(
        `${REACT_APP_WHATSAPP_API}/groups?token=${REACT_APP_WHATSAPP_TOKEN}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          method: 'GET',
        },
      );

      // dispatch an action to save the company

      // End loading
      dispatch(endCompanyUpdate());

      return whatsAppGroups;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(endCompanyUpdate());

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST and create a company website config
 *
 * @param config Company website creation parameters
 * @param requestFn request function
 */
export const createWebsiteConfig =
  (config: ICreateWebsiteConfig, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IConfigFile> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Get all Companies
      const response = await requestFn<IConfigFile>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.CREATE_WEBSITE_CONFIG}`,
        {
          method: 'POST',
          body: JSON.stringify(config),
        },
      );

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST and create a company website config
 *
 * @param config Company website update config parameters
 * @param uuid config file uuid
 * @param requestFn request function
 */
export const updateWebsiteConfig =
  (config: IUpdateWebsiteConfig, uuid: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IConfigFile> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Get all Companies
      const response = await requestFn<IConfigFile>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.UPDATE_WEBSITE_CONFIG}/${uuid}`,
        {
          method: 'PUT',
          body: JSON.stringify(config),
        },
      );

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      // end update
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST and fetch a company website config file
 *
 * @param uuid company
 * @param requestFn request function
 */
export const fetchWebsiteConfig =
  (uuid: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IConfigFile> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startCompanyUpdate());

      // Get company website config file
      const response = await requestFn<IConfigFile>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_WEBSITE_CONFIG}/${uuid}`,
        {
          method: 'GET',
        },
      );

      dispatch(saveCompanyWebsiteConfig(response));

      // End loading
      dispatch(endCompanyUpdate());

      return response;
    } catch (err) {
      // set api Error if any
      dispatch(setApiError(err));

      dispatch(saveCompanyWebsiteConfig(undefined));

      // end update
      dispatch(endCompanyUpdate());

      return err as ResponseError;
    }
  };
