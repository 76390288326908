import { TranslatedPair } from '../lib/hooks/translation/translations';

//******************** SAFIRI API ROUTES *****************************/
export const LOGON = '/api/logon';
export const REGISTER_SAFIRI_USER = '/api/register';
export const VERIFY = '/api/verify';
export const VERIFY_EMAIL = '/api/verify/email?email=';
export const VERIFY_MOBILE = '/api/verify/mobile?';
export const EDIT_PROFILE = '/api/user/';
export const REMOVE_STAFF = '/api/user/';
export const GET_ALL_STAFF = '/api/users';
export const GET_ALL_SAFIRI_STAFF = '/api/users/safiri';
export const GET_USER = '/api/user?';
export const FETCH_USERS = '/api/users/parcel';
export const PASSWORD = '/api/password';
export const GET_ALL_CITIES = '/api/cities';
export const GET_ALL_CURRENCY = '/api/currency/all';
export const ADD_SIMCARD_RECHARGE = '/api/sim-card-recharge';
export const ALL_SIMCARD_RECHARGE = '/api/sim-card-recharge';
export const SEARCH_SIMCARD_RECHARGE = '/api/sim-card-recharge/search';
export const SIMCARD_RECHARGE_TOTALS = '/api/sim-card-recharge/totals';
export const GET_ALL_ACTIVITY_LOGS = '/api/logs';
export const ADD_APPLICATION = '/api/application';
export const GET_ALL_PENDING_APPLICATIONS = '/api/applications';
export const GET_BUSINESS = '/api/business';

//******************** CLIENT ADMIN API ROUTES *****************************/
export const REGISTER_COMPANY = '/api/company/register';
export const FIND_COMPANY = '/api/company?';
export const ALL_COMPANIES = '/api/companies';
export const UPDATE_COMPANY = '/api/company/';
export const ROUTE_UPDATE = '/api/company/';
export const CREATE_TIMETABLE = '/api/company/';
export const ALL_COMPANY_DISCOUNTS = '/api/discounts';
export const COMPANY_DISCOUNT = '/api/discount';
export const ALL_TICKETS = '/api/tickets';
export const TRIP = '/api/trip';
export const TRIPS = '/api/trips';
export const TRIPSMADE = '/api/trips/made';
export const TICKETS_SOLD = '/api/tickets';
export const ACTIVATE_TRIP = '/api/trip/activate';
export const UPDATE_TRIP = '/api/trip/update';
export const CREATE_EXPENSE = '/api/expense/bulk';
export const UPDATE_EXPENSE = '/api/expense';
export const GET_EXPENSES = '/api/expenses';
export const GET_COMPANY_EXPENSES = '/api/expenses/company';
export const DELETE_EXPENSE = '/api/expense';
export const GENERATE_EXPENSES_PDF = '/expense/pdf/generate';
export const ALL_INVENTORY = '/api/inventory';
export const SEARCH_SIMCARDS = '/api/sim-card-recharge/sim-cards/search';
export const ADD_INVENTORY = '/api/inventory';
export const COMPANY_INVENTORY = '/api/inventory/search';
export const GENERATE_PARCEL_SALES_PDF = '/api/parcel/generate-sales-pdf';
export const GENERATE_PARCEL_SALES_XLSX = '/api/parcel/generate-sales-xlsx';
export const GENERATE_TICKET_SALES_PDF = '/api/ticket/pdf/sales';
export const GENERATE_TICKET_SALES_XLSX = '/api/ticket/xlsx/sales';
export const GENERATE_LEASE_SALES_PDF = '/api/lease-vehicle/pdf/sales';
export const GENERATE_TRIP_MANIFEST_PDF = '/api/trip/manifest';
export const REVENUE = '/api/revenue';
export const REVENUE_PDF_DOWNLOAD = '/api/revenue/download/pdf';
export const AGGREGATOR_COLLECTIONS = '/api/revenue/aggregator-collections';
export const PUSH_NOTIFICATION_COMPANY = '/api/company/';
export const SEND_PUSH_NOTIFICATION = '/push-notification';
export const ADD_USER_CREDIT = '/api/credit/payment/manual';
export const ADD_USER_WALLET_DEBIT = '/api/credit/wallet-debit/manual';
export const GET_USER_PAYMENT_REQUESTS = '/api/credit/payment/requests';
export const GET_USER_CREDIT_TRANSACTION = '/api/credit/credit-statement';
export const GET_TRANSACTIONS = '/api/credit/statement';
export const VERIFY_USER_TRANSACTION = '/api/credit/payment/verification';
export const GET_DASHBOARD_SUMMARIES =
  '/api/company/dashboard/dashboard-summaries';
export const GET_DASHBOARD_INCOME_SUMMARIES =
  '/api/company/dashboard/dashboard-summaries/income';
export const GET_AGENT_DASHBOARD_INCOME_SUMMARIES = '/api/agent/stats/income';
export const CREATE_CUSTOMER = '/api/non-transit-agency-company';
export const SEARCH_CUSTOMERS = '/api/non-transit-agency-company';
export const UPDATE_CUSTOMER = '/api/non-transit-agency-company';
export const GENERATE_INVOICE = '/api/invoice';
export const GET_INVOICES = '/api/invoice';
export const UPDATE_INVOICE = '/api/invoice/update';
export const DOWNLOAD_INVOICE_PDF = '/api/invoice/pdf/share-invoice-pdf';
export const REMOVE_PARCEL_FROM_INVOICE = '/api/invoice/parcel/remove';
export const CREATE_PAYMENT_OPTION = '/api/company/payment-options';
export const UPDATE_PAYMENT_OPTION = '/api/company/payment-options';
export const DELETE_PAYMENT_OPTION = '/api/company/payment-option';
export const CREATE_USER_PAYMENT_OPTION = '/api/user/payment-options';
export const UPDATE_USER_PAYMENT_OPTION = '/api/user/payment-options';
export const DELETE_USER_PAYMENT_OPTION = '/api/user/payment-option';
export const CREATE_WEBSITE_CONFIG = '/api/config-file';
export const UPDATE_WEBSITE_CONFIG = '/api/config-file';
export const DELETE_WEBSITE_CONFIG = '/api/config-file';
export const GET_WEBSITE_CONFIG = '/api/config-file';

// vehicle routes
export const CREATE_VEHICLE = '/api/vehicle';
export const UPDATE_VEHICLE = '/api/vehicle/';
export const DELETE_VEHICLE = '/api/vehicle/';
export const ALL_VEHICLES = '/api/vehicles';
export const GET_SEAT_GRID_TEMPLATES = '/api/vehicles/seating-template';
export const SAVE_SEAT_GRID_TEMPLATES = '/api/vehicle/seating-template';

// plan routes
export const ALL_PLANS = '/api/plans';
export const CREATE_PLAN = '/api/plan';
export const GET_PLAN = '/api/plan?';
export const UPDATE_PLAN = '/api/plan/:planUUID';
export const DELETE_PLAN = '/api/plan?';

//******************** GTFS ROUTES *****************************/
export const GTFS_ROUTES = '/api/routes';
export const GTFS_SHAPE = '/api/shape?route_id=';
export const GTFS_STOPS_BY_ROUTE_ID = '/api/stop?route_id=';
export const ALL_GTFS_STOPS = '/api/stops';
export const GTFS_TRIP = '/api/trip';
export const GTFS_FARE = '/api/fare';
export const TRIP_PREVIEW = '/api/trip/preview?';
export const GET_STOPS = '/api/stops';
export const STOP_TIME = '/api/time';

//******************** LOCAL ROUTES *****************************/
export const HOME = '/';
export const PAGE404 = '/404';
export const PAGE500 = '/500';
export const LOGIN = '/login';
export const PASSWORD_FORGOT = '/password/forgot';
export const PASSWORD_RESET = '/password/reset';
export const REGISTER = '/register';
export const DASHBOARD = '/dashboard';
export const SEND_NOTIFICATION = '/notification';

//********************* SETTINGS ROUTES */
export const SETTINGS = '/settings';
export const ADMIN = '/admin';
export const PROFILE = '/settings/profile';
export const SERVICE_PLAN_SETTINGS = '/admin/plan';

//********************* RESOURCES ROUTES ********************************/
export const STAFF = '/staff';
export const OFFICES = '/office';
export const COMPANY = '/company';
export const SALES = '/sales';
export const WEBSITE = '/website';

//********************* ACCOUNTING ROUTES ********************************/
export const INVOICES = '/invoices';
export const EXPENSES = '/expenses';
export const PAYMENT_OPTIONS = '/payment-options';

//********************* ASSETS ROUTES ********************************/
export const VEHICLE_MANAGER = '/assets/vehicle';
export const INVENTORY = '/assets/inventory';

//********************* SAFIRI ADMIN ROUTES ********************************/
export const INVENTORY_USAGE_HISTORY = '/api/inventory/usage-history/usage';

//********************* TRAVEL SECTION ROUTES ********************************/
export const TRIPS_MANAGER = '/travel/trip';
export const ROUTES = '/travel/routes';
export const PASSENGER_INFORMATION = '/travel/info';
export const REPORTED_INCIDENTS = '/travel/incidents';
export const DISCOUNTS_VOUCHERS = '/travel/discounts';
export const TIMETABLE = '/travel/timetable';

//********************* PARCELS SECTION ROUTES ********************************/
export const PARCELS = '/parcels';
export const GET_PARCELS = '/api/parcels';

//********************* LEASE SECTION ROUTES ********************************/
export const GET_LEASED_VEHICLES = '/api/lease-vehicle';

//********************* TERMS AND POLICIES ********************************/
export const TERMS_AND_CONDITIONS = '/policies/terms';
export const CANCELLATION_POLICY = '/policies/cancellation';

//********************* REAL TIME ********************************/
export const DEVICE_MESSAGE_HISTORY = '/api/gps-tracker/messages';

//********************* SAFIRI CONFIGURATIONS ********************************/
export const SAFIRI_CONFIGURATIONS =
  '/api/mobile-app-config/bee2631e-922f-460c-b857-a6dd84ce573b';

export const SAFIRI_BUSINESS_CONFIGURATIONS =
  '/api/safiri-config/bee2631e-922f-460c-b857-a6dd84ce573b';

// ********************** GLOBAL ROUTE NAMES **************************/
export const ROUTE_NAMES = {
  HOME: 'HOME',
  DASHBOARD: 'DASHBOARD',
  ASSETS: 'ASSETS',
  VEHICLE: 'VEHICLE',
  INVENTORY: 'INVENTORY',
  RESOURCES: 'RESOURCES',
  STAFF: 'STAFF',
  COMPANY: 'COMPANY',
  OFFICES: 'OFFICES',
  TRAVEL: 'TRAVEL',
  REPORTS: 'REPORTS',
  ACCOUNTING: 'ACCOUNTING',
  INVOICES: 'INVOICES',
  TRIPS: 'TRIPS',
  SALES: 'SALES',
  PARCELS: 'PARCELS',
  TIMETABLE: 'TIMETABLE',
  SETTINGS: 'SETTINGS',
  ADMIN: 'ADMIN',
  SERVICE_PLAN_SETTINGS: 'SERVICE_PLAN_SETTINGS',
  PROFILE: 'PROFILE',
  CANCELLATION_POLICY: 'CANCELLATION_POLICY',
  REPORTED_INCIDENTS: 'REPORTED_INCIDENTS',
  DISCOUNTS_VOUCHERS: 'DISCOUNTS_VOUCHERS',
  ROUTES: 'ROUTES',
  TERMS_AND_POLICIES: 'TERMS_AND_POLICIES',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  PASSENGER_INFORMATION: 'PASSENGER_INFORMATION',
  EXPENSES: 'EXPENSES',
  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  PAYMENT_OPTIONS: 'PAYMENT_OPTIONS',
  WEBSITE: 'WEBSITE',
};

// -------------------- TRANSLATE ROUTE NAMES ------------------------/
export const createRouteNames = (
  translation: (key: string, config?: any) => string,
): TranslatedPair => {
  return {
    [ROUTE_NAMES.DASHBOARD]: translation('sidebar.dashboard'),
    [ROUTE_NAMES.ASSETS]: translation('sidebar.assets.title'),
    [ROUTE_NAMES.VEHICLE]: translation('sidebar.assets.vehicles'),
    [ROUTE_NAMES.INVENTORY]: translation('sidebar.assets.inventory'),
    [ROUTE_NAMES.HOME]: translation('sidebar.home'),
    [ROUTE_NAMES.OFFICES]: translation('sidebar.offices'),
    [ROUTE_NAMES.COMPANY]: translation('sidebar.company'),
    [ROUTE_NAMES.PAYMENT_OPTIONS]: translation('sidebar.paymentOptions'),
    [ROUTE_NAMES.WEBSITE]: translation('sidebar.website'),
    [ROUTE_NAMES.STAFF]: translation('sidebar.users'),
    [ROUTE_NAMES.TRAVEL]: translation('sidebar.travel'),
    [ROUTE_NAMES.REPORTS]: translation('sidebar.reports'),
    [ROUTE_NAMES.ACCOUNTING]: translation('sidebar.accounting'),
    [ROUTE_NAMES.INVOICES]: translation('sidebar.invoices'),
    [ROUTE_NAMES.TRIPS]: translation('sidebar.trips'),
    [ROUTE_NAMES.SALES]: translation('sidebar.sales'),
    [ROUTE_NAMES.PARCELS]: translation('sidebar.parcels'),
    [ROUTE_NAMES.TIMETABLE]: translation('sidebar.timetable'),
    [ROUTE_NAMES.SETTINGS]: translation('sidebar.settings'),
    [ROUTE_NAMES.PROFILE]: translation('sidebar.userProfile'),
    [ROUTE_NAMES.RESOURCES]: translation('sidebar.resources'),
    [ROUTE_NAMES.ROUTES]: translation('sidebar.routes'),
    [ROUTE_NAMES.PASSENGER_INFORMATION]: translation(
      'sidebar.passengerInformation',
    ),
    [ROUTE_NAMES.REPORTED_INCIDENTS]: translation('sidebar.incidents'),
    [ROUTE_NAMES.DISCOUNTS_VOUCHERS]: translation('sidebar.discounts'),
    [ROUTE_NAMES.TERMS_AND_POLICIES]: translation('sidebar.termsPolicies'),
    [ROUTE_NAMES.TERMS_AND_CONDITIONS]: translation(
      'sidebar.termsAndCondition',
    ),
    [ROUTE_NAMES.CANCELLATION_POLICY]: translation(
      'sidebar.cancellationPolicy',
    ),
    [ROUTE_NAMES.EXPENSES]: translation('sidebar.expenses'),
    [ROUTE_NAMES.SEND_NOTIFICATION]: translation('sidebar.sendNotification'),
  };
};

/**
 * @description React-JoyRide constant must be the last word of the routes
 * These will be used as the key of the tourSteps object in the joyride.ts
 * module in the constants folder.
 *
 * These must be update whenever a new route is added
 */
export const JOYRIDE_KEYS = {
  DASHBOARD: 'dashboard',
  LOGIN: 'login',
  REGISTER: 'register',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  STAFF: 'staff',
  COMPANY: 'company',
  OFFICES: 'offices',
  VEHICLE: 'vehicle',
  INVENTORY: 'inventory',
  TRIPS: 'trip',
  SALES: 'sales',
  PARCELS: 'parcels',
  ROUTES: 'routes',
  PASSENGER_INFORMATION: 'info',
  REPORTED_INCIDENTS: 'incidents',
  DISCOUNTS_VOUCHERS: 'discounts',
  TIMETABLE: 'timetable',
  TERMS_AND_CONDITIONS: 'terms',
  CANCELLATION_POLICY: 'cancellation',
  INVOICES: 'invoices',
  EXPENSES: 'expenses',
  PAYMENT_OPTIONS: 'payment',
  WEBSITE: 'website',
};

export enum RouteStations {
  PICK_UP = 'pickUpStations',
  DROP_OFF = 'dropOffStations',
}

// for arranging and calculating stop times
// speed in kilometers per hour
export const defaultVehicleSpeed = 80;

// Default departure time (HH:MM:SS) for initialising trip schedules
export const defaultDepartureTime = '06:00:00';
